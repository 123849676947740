import React, {Component} from "react";
import {render} from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "mobx-react";
import {AppContainer} from "react-hot-loader";
import App from "./containers/App";
import * as stores from './stores/stores';
import {GlobalStyle} from "./utils/global-styles";

class ScrollToTop extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}


const renderApp = () => {
    render(
        <AppContainer>
            <Router>
                <ScrollToTop>
                    <GlobalStyle/>
                    <Provider {...stores}>
                        <App/>
                    </Provider>
                </ScrollToTop>
            </Router>
        </AppContainer>,
        document.getElementById("root")
    );
};

renderApp(App);

if (module.hot) {
    module.hot.accept(() => renderApp(App));
}
