import axios from 'axios';

let parseString = require('xml2js').parseString;

export function unescapeHTML(escapedHTML) {
    return escapedHTML.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
}

export function parser(response, type) {
    return new Promise((resolve, reject) => {
        parseString(unescapeHTML(response), (err, result) => {
            let res = result.string._;
            let code = null;
            let data = null;
            let totalRows = null;
            if (type === 'S') {
                code = res.substring(0, 3);
                data = res.substring(5);
            } else {
                code = res.substring(0, 3);
                if (result.string && result.string.NewDataSet) {
                    for (let k in result.string) {
                        if (k !== '$' && k !== '_') {
                            data = result.string[k][0]['Table'];
                            if (result.string[k][0] && result.string[k][0]['$']) {
                                if (result.string[k][0]['$'] && result.string[k][0]['$']['RowsCount'])
                                    totalRows = parseInt(result.string[k][0]['$']['RowsCount'], 10);
                            }

                        }
                    }
                } else {
                    totalRows = 0
                }
                if (data === null) {
                    code = res.substring(0, 3);
                    data = res.substring(5);
                }
            }

            if (code === '001') {
                if (totalRows !== null) {
                    data = {data: data, totalRows: totalRows};
                    if (totalRows === 0)
                        data = {data: [], totalRows: totalRows};
                }

                resolve(data);
            } else {
                reject(data);
            }
        });
    })
}

axios.defaults.timeout = 20000;

if (window.location.host.indexOf("gov.ct.tr") > -1) {
    axios.defaults.baseURL = "https://endpoint.basbakanlik.gov.ct.tr/aidmain.asmx";

} else {
    axios.defaults.baseURL = "https://student-visa-gateway.azurewebsites.net/aidmain.asmx";
    // axios.defaults.baseURL = "http://desktop-j9j1l64:55018/Main.asmx";
}

export const healthAddress = `https://healthministry.z6.web.core.windows.net/${window.location.host.indexOf("gov.ct.tr") > -1 ? "prod" : "dev"}/`

export const API = axios;
