import React, {Component} from "react";
import {Route, withRouter, Switch} from "react-router-dom";
import LazyRoute from "lazy-route";

@withRouter
export default class App extends Component {
    render() {
        return (
            <Switch>
                <Route
                    exact
                    path="/"
                    render={props => (
                        <LazyRoute {...props} component={import("./aidDelivery/Apply")}/>
                    )}
                />
                <Route
                    exact
                    path="/verify"
                    render={props => (
                        <LazyRoute {...props} component={import("./aidDelivery/Permit")}/>
                    )}
                />
            </Switch>
        );
    }
}
