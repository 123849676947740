import { createGlobalStyle } from 'styled-components';
export const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  html,
  body {
    background-color: #fafafa;
    margin: 0;
    padding: 0;
  }

  body {
    color: #505050;
  }

  body,
  button,
  input,
  select {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
  }

  hr {
    border: none;
    border-top: 1px solid #eaecef;
  }

  strong {
    font-weight: 500;
  }

  .text-center {
    text-align: center;
  }

  a {
    text-decoration: none;
  }

  p a {
    color: #737373;
    font-weight: 500;
    text-decoration: none;
  }
`;
