import {observable, action} from 'mobx';
import {API, parser} from "../utils/Api";
import qs from "qs";

const flattenItems = (items) => {
    let flatItems = [];
    items.map(item => {
        let flatItem = {};
        for (let k in item) {
            flatItem[k] = item[k][0]
        }
        flatItems.push(flatItem);
        return null;
    });
    return flatItems;
};

const postConfig = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
}

class AidDelivery {
    @observable districts = [];
    @observable headmen = [];
    @observable municipalities = [];
    @observable workStatuses = [];
    @observable nationalities = [];
    @observable printData = {};

    @action
    async getAllDistricts() {
        let {data} = await API.get('/_GetAllDistricts').catch(e => alert(e.response.data));
        return parser(data).then(d => {
            this.districts = flattenItems(d.data);
            // console.log(flattenItems(d.data))
        }).catch(e => alert(e))
    }


    @action
    async getAllNationalities() {
        let {data} = await API.get('/_GetAllNationalities').catch(e => alert(e.response.data));
        return parser(data).then(d => {
            this.nationalities = flattenItems(d.data);
            // console.log(flattenItems(d.data))
        }).catch(e => alert(e))
    }


    @action
    async getAllHeadmen(iMunicipalityRecNo) {
        let params = {
            iMunicipalityRecNo
        };
        let {data} = await API.post('/_GetAllHeadmen', qs.stringify(params), postConfig).catch(e => console.log(e.response));
        return parser(data).then(d => {
            this.headmen = flattenItems(d.data);
            // console.log(flattenItems(d.data))
        }).catch(e => alert(e))
    }


    @action
    async getAllMunicipalities(iDistrictRecNo) {
        let params = {
            iDistrictRecNo
        };
        let {data} = await API.post('/_GetAllMunicipalities', qs.stringify(params), postConfig).catch(e => console.log(e.response));
        return parser(data).then(d => {
            this.municipalities = flattenItems(d.data);
            // console.log(flattenItems(d.data))
        }).catch(e => alert(e))
    }


    @action
    async getAllWorkStatuses() {
        let {data} = await API.get('/_GetAllWorkStatuses').catch(e => alert(e.response.data));
        return parser(data).then(d => {
            this.workStatuses = flattenItems(d.data);
            // console.log(flattenItems(d.data))
        }).catch(e => alert(e))
    }


    @action
    async verifyPhoneNumber(PhoneNumber, iNationality, sPassID, sName, sSurname, dBirthDate) {
        let params = {
            PhoneNumber,
            iNationality,
            sPassID,
            sName,
            sSurname,
            dBirthDate
        };
        let {data} = await API.post('/_VerifyPhoneNumber', qs.stringify(params), postConfig).catch(e => console.log(e.response));
        try {
            return await parser(data, "S")
        } catch (e) {
            alert(e)
        }
    }

    @action
    async applyForAid(sPassID, sName, sSurname, iWorkStatus, sHomePhoneNo, sMobileNo, iDistrictRecordNo, iMunicipalityRecordNo, iHeadmanRecordNo, sAddress, dBirthDate, iNationality, iNumberOfPeople, iIncome, sHealthIssue, sEmail, smsGuid, smsCode) {
        let params = {
            sPassID,
            sName,
            sSurname,
            iWorkStatus,
            sHomePhoneNo,
            sMobileNo,
            iDistrictRecordNo,
            iMunicipalityRecordNo,
            iHeadmanRecordNo,
            sAddress,
            dBirthDate,
            iNationality,
            iNumberOfPeople,
            iIncome,
            sHealthIssue,
            sEmail,
            smsGuid,
            smsCode
        };
        let {data} = await API.post('/_ApplyForAid', qs.stringify(params), postConfig).catch(e => console.log(e.response));
        try {
            let d = await parser(data, "S")
            alert(d);
            return d;
        } catch (e) {
            if (e === "SMS kodu yanlış / SMS code is wrong") {
                return e
            }
            alert(e)
        }
    }

    @action
    async verifyAidByCRC(RecNo, CRC) {
        this.printData = null
        let params = {
            RecNo,
            CRC
        };
        let {data} = await API.post('/_VerifyAidByCRC', qs.stringify(params), postConfig).catch(e => console.log(e.response));
        return parser(data).then(d => {
            if (Array.isArray(d)) {
                this.printData = flattenItems(d)[0];
                return flattenItems(d)[0]
            }
        }).catch(e => {
            alert(e);
        });
    }


}

const store = new AidDelivery();
export default store;
